<template>
    <ODialog v-model:show="showDialog"  disableMovable disableResize static backdrop noCloseButton
        :title="$t('Data Import/Update from Excel')">
        <div class="o365-dialog-body">
            <div v-for="state in dataObject.importTemplate.importStates.states" :class="{'text-success':state.completed}">
                {{state.message}} 
                <div class="spinner-border spinner-border-sm me-1" role="status" v-if="!state.completed && dataObject.importTemplate.importStates.isActive(state)">
                    <span class="sr-only"></span>
                </div>
                <i class="bi bi-check" v-if="state.completed"></i>
            </div>
            <div class="text-danger" v-if="dataObject.importTemplate.rowsFailed">
                {{dataObject.importTemplate.rowsFailed}} {{$t('rows failed')}}.
            </div>
        </div>
            <div class="o365-dialog-footer">
                <button type="button" v-if="dataObject.importTemplate.completed && !hideDataLog" @click = "{openViewDataLog(); showDialog=false;}" class="btn btn-primary btn-sm">{{ $t('View Data Log') }}</button>
                <button type="button" v-if="dataObject.importTemplate.completed" @click="() => {cancel();showDialog=false;}" class="btn btn-primary btn-sm">{{ $t('Close') }}</button>
                <button type="button" v-if="!dataObject.importTemplate.completed" @click = "{cancel(); showDialog=false;}" class="btn btn-warning btn-sm">{{ $t('Cancel') }}</button>
            </div>
    </ODialog>
    <ImortDataLogModal v-if="!props.openDataLog" ref="refViewDataLog" :dataObject = "props.dataObject"/> 
</template>

<script setup>
    import './DataObject.ImportTemplate.ts';
    import { useAsyncComponent } from 'o365-vue-utils';
    import { ODialog } from 'o365-ui-components';

    import {  ref } from "vue";
    const ImortDataLogModal = useAsyncComponent('./ImortDataLogModal.vue', { importFn: () => import('./ImortDataLogModal.vue')});
    const props = defineProps(['dataObject','batch', 'openDataLog','hideDataLog']);
    const refViewDataLog = ref(null);
    const showDialog = ref(false);

    const openViewDataLog = () =>{
        if(props.openDataLog ){
            props.openDataLog.call();
            return;
        }

        refViewDataLog.value.modalRef.show();
    }
    const cancel = () =>{
        props.dataObject.importTemplate.cancel();
     }
    function dismiss(){
        if(props.dataObject.importTemplate.rowsFailed==0){
            showDialog.value= false;
        }

    }
    function show(){
        showDialog.value= true;

    }
    defineExpose({
        dismiss,
        show,
    });



</script>